import React from "react"
import { graphql } from 'gatsby'

// components
import Layout from "../components/Layout"

// markup
const IndexPage = ({ data }) => {
  console.log('data: ', data)
  
  return (
    <Layout>
      <h2>All Simple Pages:</h2>
      <p>(note: this is for internal use only.)</p>

      <ul>
        {data.allContentfulSimplePage.edges.map((item) => {
          return (
            <li>
              <ArticleLink title={item.node.title} urlSlug={item.node.urlSlug} /> 
            </li>
          )
        })}
      </ul>
      
    </Layout>
  )
}

const ArticleLink = ({ title, urlSlug }) => {
  return (
    <>
      <b>{title}:</b> <a href={"/" + removeLeadingSlash(urlSlug)}>{urlSlug}</a>
    </>
  )
}

// sometimes people add a "/" - it needs to be removed otherwise we get "//" (causing a broken link)
function removeLeadingSlash(str) {
  return str.slice(str.startsWith("/") ? 1 : 0);
}

export default IndexPage

export const query = graphql`
query GetAllSimplePages1 {
  allContentfulSimplePage {
    edges {
      node {
        urlSlug
        title
      }
    }
  }
}
`